// @flow
import * as React from 'react';
import {Button, ButtonGroup} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

type Props = {

};
export const NavTabs = (props: Props) => {
    const location =useLocation();
    const btnClass = 'btn-light btn-sm';
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const classMobile = 'card font-size-secondary mt-4 bg-light fixed-bottom m-auto';
    const classDesktop = 'nav font-size-secondary mt-4 bg-light m-auto';
    return (
        <div className={(isTabletOrMobile ? classMobile : classDesktop)} style={{
            width:'100%',
            height:'4em',
            margin: 'auto'
        }}>
            <div className={'m-auto'}>
                <ButtonGroup className={'d-flex'} >
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <Button className={location.pathname === '/' ? btnClass +' active' : btnClass }>Schedules</Button>
                    </Link>
                    {/*<Link to={'/live'} style={{ textDecoration: 'none' }}>*/}
                    <Button href={'https://gasworks.rabonesystems.net/'} className={btnClass}>Live Control</Button>
                    {/*</Link>*/}
                </ButtonGroup>




            </div>
        </div>
    );
};
