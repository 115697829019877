// @flow
import React from 'react';
import TimePicker from "react-time-picker";
import {calendar} from "../interfaces/interfaces";
import {ButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import '../styles/react-date-range/styles.css';
import '../styles/react-date-range/default.css';
import {DateRange} from 'react-date-range';

type Props = {
    calendar: calendar,
    mode: number,
    onTimeChange: any,
    onWeekChange: any,
    onDateChange: any
};


const renderDaily = ({calendar, mode, onTimeChange}: Props) => {
    return (
        (mode === 1) ?
            <div className={'d-flex '}>
                <p className={'text-primary text-center mr-3 mt-3'}>at</p>
                <TimePicker onChange={(t) => {
                    onTimeChange(t)

                }} value={calendar.startTime} clearIcon={null} clockIcon={null} hourPlaceholder={'hh'}
                            minutePlaceholder={'mm'}/>

            </div> : null

    );

};

const renderWeekly = ({calendar, mode, onTimeChange, onWeekChange}: Props) => {

    return (
        (mode === 2) ?
            <div>
                <p className={'text-primary d-flex justify-content-center'}>Every</p>
                <ButtonGroup size='sm' className="m-1 d-flex ">
                    <Button variant={'muted'} className={'flex-fill'} onClick={() => {
                        onWeekChange(1)
                    }}><strong>{calendar.monday ? 'Mon' : <del className={'text-secondary'}>Mon</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(2)
                    }}><strong>{calendar.tuesday ? 'Tue' : <del className={'text-secondary'}>Tue</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(3)
                    }}><strong>{calendar.wednesday ? 'Wed' : <del className={'text-secondary'}>Wed</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(4)
                    }}><strong>{calendar.thursday ? 'Thu' : <del className={'text-secondary'}>Thu</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(5)
                    }}><strong>{calendar.friday ? 'Fri' : <del className={'text-secondary'}>Fri</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(6)
                    }}><strong>{calendar.saturday ? 'Sat' : <del className={'text-secondary'}>Sat</del>}</strong></Button>
                    <Button variant='muted' className={'flex-fill'} onClick={() => {
                        onWeekChange(7)
                    }}><strong>{calendar.sunday ? 'Sun' : <del className={'text-secondary'}>Sun</del>}</strong></Button>
                </ButtonGroup>
                <br/>

                <div className={'d-flex justify-content-center'}>
                    <p className={'text-primary text-center mr-3 mt-3'}>at</p>
                    <TimePicker onChange={(t) => {
                        onTimeChange(t)

                    }} value={calendar.startTime} clearIcon={null} clockIcon={null} hourPlaceholder={'hh'}
                                minutePlaceholder={'mm'}/>

                </div>
            </div> : null

    );

};

const renderDate = ({mode,onDateChange,calendar,onTimeChange}: Props) => {
    let dateCheck = new Date(calendar.startDate).toDateString();
    if(dateCheck === "Invalid Date"){
        calendar.startDate = new Date().toDateString();
        calendar.endDate = new Date().toDateString();
    }
    const selectionRange = {
        startDate: new Date(calendar.startDate),
        endDate: new Date(calendar.endDate),
        key: 'selection',
    }

    return (
        (mode === 3) ?
            <div>
                <DateRange editableDateInputs={false}
                           moveRangeOnFirstSelection={false}
                           ranges={[selectionRange]}

                           onChange={(range) => {
                              onDateChange(range)

                           }}

                />
                <div className={'d-flex justify-content-center'}>
                    <p className={'text-primary text-center mr-3 mt-3'}>at</p>
                    <TimePicker onChange={(t) => {
                        onTimeChange(t)

                    }} value={calendar.startTime} clearIcon={null} clockIcon={null} hourPlaceholder={'hh'}
                                minutePlaceholder={'mm'}/>

                </div>


            </div> : null

    );

};

export const DateTimeDisplay = (props: Props) => {

    return (
        <>
            {renderDaily(props)}
            {renderWeekly(props)}
            {renderDate(props)}
        </>
    );

};
