// @flow
import * as React from 'react';
import {Col, Form, InputGroup, Row} from "react-bootstrap";

type Props = {

};
export const InstagramOptions = (props: Props) => {
    return (
        <>
            <Row>
                <Form.Group as={Col} md="4" controlId="hashtag1">
                    <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend" className={'text-muted'}>#</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Hashtag1"
                            aria-describedby="inputGroupPrepend"
                        />
                        <InputGroup.Text className={'text-muted'}><Form.Check
                            type="switch"
                            id="active-switch"
                            onChange={()=>{}}
                            checked={false}
                            label={false ? "" : "inactive"}
                        /></InputGroup.Text>
                    </InputGroup>

                </Form.Group>

            </Row>
            <Row>
                <Form.Group as={Col} md="4" controlId="hashtag2">
                    <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend" className={'text-muted'}>#</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Hashtag2"
                            aria-describedby="inputGroupPrepend"
                        />
                        <InputGroup.Text className={'text-muted'}><Form.Check
                            type="switch"
                            id="active-switch"
                            onChange={()=>{}}
                            checked={true}
                            label={true ? "" : "inactive"}
                        /></InputGroup.Text>
                    </InputGroup>

                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="4" controlId="hashtag3">
                    <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend" className={'text-muted'}>#</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Hashtag3"
                            aria-describedby="inputGroupPrepend"
                        />
                        <InputGroup.Text className={'text-muted'}><Form.Check
                            type="switch"
                            id="active-switch"
                            onChange={()=>{}}
                            checked={false}
                            label={false ? "" : "inactive"}
                        /></InputGroup.Text>
                    </InputGroup>

                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="4" controlId="hashtag4">
                    <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend" className={'text-muted'}>#</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Hashtag4"
                            aria-describedby="inputGroupPrepend"
                        />
                        <InputGroup.Text className={'text-muted'}><Form.Check
                            type="switch"
                            id="active-switch"
                            onChange={()=>{}}
                            checked={true}
                            label={true ? "" : "inactive"}
                        /></InputGroup.Text>
                    </InputGroup>

                </Form.Group>
            </Row>

        </>


    );
};
