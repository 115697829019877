// @flow
import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import Editor from "./editor";
import {event, schedule} from "../interfaces/interfaces";

type Props = {
    events: event[];
    onUpdate: any;
};

const AddSchedule = (props: Props) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>

            <Editor new={true} events={props.events} onSave={(data: schedule) => {
                props.onUpdate({...data})
            }} renderButton={true}/>


        </>

    );
};

export default AddSchedule;
