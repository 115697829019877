//Drag handler
import {SortableHandle} from "react-sortable-hoc";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import '../styles/drag.css';
interface propsI{
    active:boolean
}
const DragHandler = SortableHandle(({active}:propsI) => (
    <span>
    <FontAwesomeIcon icon={faCalendarCheck} className={(active ? 'text-info': '')} />
  </span>
));

export default DragHandler;
