// @flow
import * as React from 'react';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import Loader from 'react-loader-spinner';
import {Redirect} from 'react-router-dom';
import {Button} from "react-bootstrap";

type Props = {};

const USER_LOGIN = gql`
  mutation userLogin($username: String,$password: String){
  userLogin(username: $username, password: $password)
  }`;

const TOKEN_VALIDATE = gql`
  mutation tokenValidate($token: String){
  tokenValidate(token: $token)
  }`;


export const AutoLogin = (props: Props) => {
    const p = useParams<{ id?: string }>();
    // console.log(p)

    const [username, setUsername] = useState('manager');
    const [password, setPassword] = useState((p && p.id) ? p.id : '');
    const [loginState, setLoginState] = useState(0);
    const [userLogin, {
        error: userLoginError,
        loading: userLoginLoading
    }] = useMutation<{ userLogin: string }, { username: string, password: string }>(USER_LOGIN, {
        variables: {
            username: username,
            password: password
        }
    });

    useEffect(() => {
        userLogin().then((res) => {
            if (res && res.data) {
                setTimeout(() => {
                    setLoginState(3);
                }, 500)
                //props.onLogin();
                // console.log(res.data.userLogin)
                localStorage.setItem("token", res.data.userLogin);
            }
        });
    }, []) // mount

    return (
        <div>

            {loginState === 3 ? <Redirect push to="/"/> : null}
            {userLoginError ? <div className={'m-auto'}>
                <div>Server Unavailable</div>
                <Button href={'https://gasworks.rabonesystems.net/'} className={''}>Return to Live Control</Button>
            </div> : null}
        </div>
    );
};
