import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSatelliteDish, faGem} from '@fortawesome/free-solid-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import Editor from "./editor";
import {event, schedule} from "../interfaces/interfaces";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {ButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";

function divd() {
    return <small style={{paddingLeft: '10px'}}> | </small>;
}

interface IProps {
    data: any;
    events: event[];
    onUpdate: any;
    onDelete: any;
}

const Schedule = ({data, events, onUpdate, onDelete}: IProps) => {


    const w = '210px';
    const start = new Date(data.calendar.startDate).toDateString();
    const end = new Date(data.calendar.endDate).toDateString();

//
    const renderDateReadOnly = () => {
        switch (data.mode) {
            case (1): {
                return (<small className={'d-flex justify-content-center w-100'}><strong>Every day</strong></small>);

            }
            case (2): {
                return (<small className={'d-flex justify-content-between w-100'}>

                    <div className={'text-muted mr-1'}><strong>{data.calendar.monday ?
                        <div className={'text-dark'}>Mon</div> : <del className={'text-secondary'}>Mon</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.tuesday ?
                        <div className={'text-dark'}>Tue</div> : <del className={'text-secondary'}>Tue</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.wednesday ?
                        <div className={'text-dark'}>Wed</div> : <del className={'text-secondary'}>Wed</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.thursday ?
                        <div className={'text-dark'}>Thu</div> : <del className={'text-secondary'}>Thu</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.friday ?
                        <div className={'text-dark'}>Fri</div> : <del className={'text-secondary'}>Fri</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.saturday ?
                        <div className={'text-dark'}>Sat</div> : <del className={'text-secondary'}>Sat</del>} </strong>
                    </div>
                    <div className={'text-muted mr-1'}><strong>{data.calendar.sunday ?
                        <div className={'text-dark'}>Sun</div> : <del className={'text-secondary'}>Sun</del>} </strong>
                    </div>
                </small>);

            }
            case (3): {
                return (<div className={'d-flex justify-content-center w-100'}>
                    <small><strong>{start === end ? start : start + ' - ' + end}</strong></small>
                </div>);

            }

        }
    };

    return (

        <>

            <div>
                <div>{divd()}</div>
            </div>

            {/*<div style={{width:w}}><div>{data.event.name}</div></div>{divd()}*/}
            <div style={{width: w}}>
                <small><strong>{data.calendar.startTime}</strong></small>
            </div>
            {divd()}
            <div style={{width: w}} className={'d-flex align-items-center'}>
                {renderDateReadOnly()}
            </div>
            {divd()}

            <div style={{width: w}}>
                <div className={'d-flex justify-content-between ml-3 mr-3'}>
                    <OverlayTrigger

                        placement={'bottom'}
                        overlay={
                            <Tooltip id={'tooltip-show'}>
                                <div className={'font-size-secondary'}> Show
                                    mode <strong>{data.event.taskB ? 'on' : ' off' }</strong></div>

                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faGem}
                                         className={data.event.taskB ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>
                    <OverlayTrigger

                        placement={'bottom'}
                        overlay={
                            <Tooltip id={'tooltip-radar'}>
                                <div className={'font-size-secondary'}> Radar
                                    mode <strong>{data.event.taskA ? 'on' : ' off'}</strong></div>
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faSatelliteDish}
                                         className={data.event.taskA ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>
                    <OverlayTrigger

                        placement={'bottom'}
                        overlay={
                            <Tooltip id={'tooltip-instagram'}>
                                <div className={'font-size-secondary'}>Instagram
                                    hashtags <strong>{data.event.taskC ? 'on' : ' off'}</strong></div>
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faInstagram}
                                         className={data.event.taskC ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>
                </div>

            </div>
            <div>
                <div><span style={{paddingRight: '5px'}}> | </span>
                    <button type="button" className="btn btn-link btn-outline-light"><Editor data={data} events={events}
                                                                                             onSave={(data: schedule) => {
                                                                                                 onUpdate({...data})
                                                                                             }} onDelete={() => {
                        onDelete()
                    }}/></button>
                </div>
            </div>
        </>

    );

}

export default Schedule;
