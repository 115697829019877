// @flow
import * as React from 'react';
import {InstagramOptions} from "../components/instagram-options";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

type Props = {};
export const MoreRoute = (props: Props) => {
    return (
        <div className={'w-100 m-auto'}>

            <div className={'p-5'}>
                <div className={'mt-5 text-secondary'}>
                <FontAwesomeIcon icon={faInstagram}/>
                <div className={'small'}>Instagram Options</div>
            </div>
                <div><InstagramOptions/></div>
            </div>

        </div>
    );
};
