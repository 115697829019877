import React, {useEffect, useState} from 'react';
import './App.scss';
import './styles/globalText.css'
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';
import SchedulerRoute from "./routes/schedulerRoute"
import {NavBarC} from "./components/navbar";
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
    gql,
    split,
    useLazyQuery, concat
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom";
import {LiveRoute} from "./routes/liveRoute";
import {LiveStatusBar} from "./components/liveStatusBar";
import {NavTabs} from "./components/NavTabs";
import {useMediaQuery} from "react-responsive";
import {Login} from "./components/login";
import {setContext} from "@apollo/client/link/context";
import {ErrorToast} from "./components/errorToast";
import {MoreRoute} from "./routes/moreRoute";
import {AutoLogin} from "./routes/auto-login";





const engine = new Styletron();

const api_url= 'https://gasworks.rabonesystems.net';
// if(api_url === ''){
//     console.log('> Missing API config!');
//
// }



function App() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [authorized,setAuthorized] = useState(false);
    const [networkError,setNetworkError] = useState(false);
    const httpLink = new HttpLink({uri: `${api_url}:4000/graphql`,});

// Log any GraphQL errors or network error that occurred
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) => {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    );
                    console.log(locations);
                    if(message === 'Login required'){
                setAuthorized(false);
                    }
                }
            );
        if (networkError) {
            console.log(`[Network error]: ${networkError.message}`);
            if(networkError.message === 'Failed to fetch'){
                setAuthorized(false);
                setNetworkError(true);
                setTimeout(()=>setNetworkError(false),5000);
            }

        }
    });

    const authMiddleware = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    // const wsLink = new WebSocketLink({
    //     uri: "ws://localhost:3000/subscriptions",
    //     options: {
    //         reconnect: false //todo set true when implementing ws
    //     }
    // });
    //
    // //routes subscriptions do WS routes and queries and mutations to HTTP routes.
    // const splitLink = split(
    //     ({ query }) => {
    //         const definition = getMainDefinition(query);
    //         return (
    //             definition.kind === 'OperationDefinition' &&
    //             definition.operation === 'subscription'
    //         );
    //     },wsLink,
    //     httpLink,
    // );


    const client = new ApolloClient({
        uri: `${api_url}:4000`,
        cache: new InMemoryCache(),
        link: from([errorLink,authMiddleware,httpLink])

    });


  return (
    <div className="App">

        <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>
                <ApolloProvider client={client}>

                <Router>

                    {authorized ? <NavBarC/> : null}
                    {authorized ? <LiveStatusBar/> : null}
                    {isTabletOrMobile ? null : (authorized ? <NavTabs/> : null) }

                    {/*{networkError && authorized ? <ErrorToast/> }*/}


                    <Switch>

                        <Route exact path="/">
                            {authorized ? <SchedulerRoute/> :  <Redirect to={"/login"}/>  }
                        </Route>
                        <Route path="/live">

                            {authorized ? <LiveRoute/> :  <Redirect to={"/login"}/>  }

                        </Route>
                        <Route path="/colorize">
                        </Route>

                        <Route path="/more">
                            {authorized ? <MoreRoute/> :  <Redirect to={"/login"}/>  }
                        </Route>


                        <Route path={"/login"}>
                            <Login onLogin={()=>setAuthorized(true)} unavailable={networkError}/>
                            {authorized ? <Redirect to={"/"}/> : null}
                        </Route>

                        <Route exact path={"/authorize/:id"} children={<AutoLogin/>}/>

                        <Route render={() => <Redirect to={{pathname: "/"}} />} />
                    </Switch>

                    {authorized && isTabletOrMobile ?  <NavTabs/> : null}

                </Router>


                </ApolloProvider>
            </BaseProvider>
        </StyletronProvider>

    </div>
  );
}

export default App;
