// @flow
import React,{ useState, useEffect } from 'react';

type Props = {
monthName?:boolean;
};
export const CurrentTime = (props: Props) => {

    const [dt, setDt] = useState(new Date().toLocaleString());
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        let secTimer = setInterval( () => {
            if(props.monthName) {
               let d = new Date();

                setDt(`${monthNames[d.getMonth()]}`)
            } else{
                setDt(new Date().toLocaleString());
            }
        },1000)

        return () => clearInterval(secTimer);
    }, []);

    return (
        <div>
            {dt}
        </div>
    );
};
