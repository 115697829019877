// @flow
import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route, useLocation,
    Link, Redirect
} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faGlobeAsia} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {user} from "../interfaces/interfaces";
import {Modal, Button, Image, Row, Col} from "react-bootstrap";
import '../styles/globalText.css';
import brisbaneLogo from "../images/brisbane.svg";
type Props = {

};

interface IUserData {
    user: user
}

const GET_USER_DATA = gql`
  query {user {
     username,
     type,
     logs
    }
  }
`;


export const NavBarC = (props: Props) => {

    const {
        loading: userLoading,
        error: userError,
        data: userData,
        refetch:userDataRefetch
    } = useQuery<IUserData>(GET_USER_DATA);

    const [showMenu,setShowMenu] = useState(false);



    return (
        <>
        <nav className="navbar navbar-light bg-dark w-100 d-flex align-content-center" style={{height:'5.5vh',position:'static',zIndex:1001}}>
            <div className="container-fluid">
            <a className="navbar-brand text-light user-select-none d-flex">
                <div><Image src={brisbaneLogo} style={{width:'28px'}} className={'mt-2'} /></div>
                <span className={'font-size-title m-1 ml-2 '}>City Lights Scheduler <div className={'small'}>Gasworks</div></span>
            </a>
                <div className="text-light user-select-none font-size-secondary" id="navbarSupportedContent">
                    <div>
                            <a className={'text-light'} onClick={()=>{userDataRefetch()
                                .then(()=>setShowMenu(true))
                                .catch((e)=>{console.log(e)});}}>
                                {userLoading ? '...' : (userError ? null :
                                        userData!.user.username
                                )} <FontAwesomeIcon icon={faUser} className={'ml-1'}/>
                            </a>

                        <Modal show={showMenu} onHide={()=>{setShowMenu(false)}} size="lg" centered>
                            <Modal.Header closeButton>
                                <div>
                               <a> <FontAwesomeIcon icon={faUser} className={'ml-1'}/> {userLoading ? '...' : (userError ? null :
                                       userData!.user.username
                               )} </a>
                                <div style={{fontSize:'10px'}}>{userLoading ? '...' : (userError ? null :
                                      userData!.user.type.toUpperCase()
                                )}</div>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div className={'d-flex justify-content-center'}>

                                    <div className={'card bg-light'} style={{height:'50vh',width:'80%', overflow: "auto"}}>
                                        <div className={'sticky-top bg-dark w-100'} style={{textAlign:"center"}}><p className={'text-light font-size-secondary m-1 user-select-none'}>User Logs</p></div>
                                        <div className={'m-2'} style={{fontSize:'12px'}}>
                                        {userLoading ? '...' : (userError ? null :
                                                userData!.user.logs.map((el,index) => <p key={index}>{'◉ ' + el}</p>)
                                        )}
                                        </div>
                                    </div>
                                </div>
                                <div className={'d-flex justify-content-center m-5'}>
                                <Button onClick={()=>{
                                    localStorage.removeItem('token');
                                }} href={'https://gasworks.rabonesystems.net/'}>Logout</Button>
                                </div>

                            </Modal.Body>

                        </Modal>

                    </div>

                </div>
            </div>
        </nav>

            </>


    );
}
