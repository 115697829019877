import React, {Component} from "react";
import "../styles/drag.css";
import arrayMove from "../helpers/arrayMove";

import SortContainer from "./SortContainer";
import SortItem from "./SortItem";
import {event, schedule} from "../interfaces/interfaces";

import AddSchedule from "./addSchedule";
import {FilterBar} from "./filterBar";




function divd() {
    return <span style={{paddingLeft: '10px'}}> | </span>;
}

interface Ifilter {
    hideInactive: boolean
}

function initFilters() {
    return {hideInactive: true};
}

interface IProps{
    schedules:schedule[],
    events:event[]
    live?:number;
    onConflict?:any
}




class SortableSchedules extends Component<IProps> {

    state = {
        schedules: this.props.schedules,
        events: this.props.events,
        filteredSchedules: this.props.schedules,
        filter: initFilters(),
        newSchedule: null
    };




    // constructor(props: any) {
    //     super(props);
    //
    //
    //
    // }

    filter() {
        let newState = {...this.state};
        //archived items will be moved to bottom
        // for (const pos in newState.schedules){
        //         if(!newState.schedules[pos].active) {
        //            newState.schedules.push(newState.schedules.splice(parseInt(pos), 1)[0]);
        //         }
        //
        // }

       // sort schedules by start time
        try {
            newState.schedules.sort((a, b) => {
                let aTime = a.calendar.startTime.split(':').map(x => +x);
                let bTime = b.calendar.startTime.split(':').map(x => +x);

                let diff = aTime[0] - bTime[0];
                return diff === 0 ? (aTime[1] - bTime[1]) : diff;

            });
        }
        catch (e){
            //deleted by another user, refresh
         this.props.onConflict();
        }


        if (this.state.filter.hideInactive) {
            //filter inactive schedules

            newState.filteredSchedules = this.state.schedules.filter(el => el.active);


        } else {
            newState.filteredSchedules = this.state.schedules;
        }





        this.setState({...newState});
    }

    scheduleUpdated(data: any, index: number) {


        let newState = {...this.state};
        if (index > -1) {

            // newState.schedules[index].name = data.event.name;
            // newState.schedules[index].event.name = data.event.name;
            newState.schedules[index] = data;
            this.setState({...newState});

        } else { //new schedule
            newState.schedules.push(data);

            this.setState({...newState});
        }

        this.filter();
    }

    scheduleDeleted(index: number) {
        let newState = {...this.state};
        if (index > -1) {
            newState.schedules.splice(index, 1);
            this.setState({...newState});
        }
        this.filter();
    }

    onSortEnd = ({oldIndex, newIndex}: any) => {
        this.setState(({schedules}: any) => ({
            schedules: arrayMove(schedules, oldIndex, newIndex)
        }));


       this.filter();
    };

    handleFilterHide = (hide: boolean) => {
        let newState = {...this.state};

        newState.filter.hideInactive = hide;

        this.setState({...newState});

        this.filter();
    };

    indexMap(uuid:string){
        let index = -1;
        this.state.schedules.find((el,i) => {
        if(el.uuid === uuid) {
            index = i;
            return true;
        }
        return false;
        });

        return index;
    }

    componentDidMount() {
        this.handleFilterHide(this.state.filter.hideInactive);
    }

    render() {



        return (
            <>



                    <div style={{width: '100%'}}>
                       <div className={'sticky-top'}> <FilterBar live={this.props.live} hideInactive={this.state.filter.hideInactive} onHide={this.handleFilterHide}/></div>
                        {(this.state.schedules.length > 0) ? null :
                            <div className="m-3">Time to create a new schedule!</div>}
                        {/*<SortContainer onSortEnd={this.onSortEnd} useDragHandle>*/}
                            <div className={'font-size-primary'}>
                            {this.state.filteredSchedules.map((value: any, index: number) => (
                                <SortItem key={`sch-${index}`} index={index} schedule={value} events={this.state.events}
                                          onDelete={() => this.scheduleDeleted(index)} onUpdate={(data: schedule) => {
                                    this.scheduleUpdated(data, this.indexMap(data.uuid));
                                }}/>
                            ))}
                            </div>
                        {/*</SortContainer>*/}
                    </div>
                    <div className="m-3"><AddSchedule events={this.state.events} onUpdate={(data: any) => {
                        this.scheduleUpdated(data, -1);
                    }}/></div>


            </>
        );
    }
}
export default SortableSchedules;

// data: any;
//     events:event[];
//     onUpdate:any;
//     onDelete:any;


/* fixme:
index.js:1 Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    at Editor (http://localhost:3000/static/js/main.chunk.js:1246:76)
    at button
    at div
    at div
    at Schedule (http://localhost:3000/static/js/main.chunk.js:792:3)
    at div
    at _c (http://localhost:3000/static/js/main.chunk.js:487:3)
    at sortableElement(_c) (http://localhost:3000/static/js/vendors~main.chunk.js:62760:96)
 */


