import React, {useEffect} from 'react';
import {faEdit, faGem, faPlusSquare, faSatelliteDish} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.css';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import '../styles/animations.css';

// import {v1 as uuidv1} from 'uuid';

import {event, calendar, schedule} from "../interfaces/interfaces";
import {TimeSelector} from "./timeSelector";
import {ButtonGroup, FormGroup} from "react-bootstrap";
import TimePicker from 'react-time-picker';
import {DateTimeDisplay} from "./dateTimeDisplay";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {gql, useMutation} from "@apollo/client";
import Schedule from "./schedule";


const initializeConfig = (data: schedule, newItem: boolean = false, initEvent: event) => {
    if (!newItem) {
        return data;
    } else {
        return {
            event: initEvent,
            mode: 2,
            uuid: '',
            name: '',
            active: true,
            calendar: {
                weekly: false, monday: true,
                tuesday: true, wednesday: true, thursday: true,
                friday: true, saturday: false, sunday: false,
                startDate: new Date().toDateString(),
                endDate: new Date().toDateString(),
                startTime: '16:00',
                endTime: '',
            },
            analytics: {
                created: "",
                createdBy: "",
                lastTriggeredBySchedule: "",
                lastTriggeredByUser: "",
                lastUser: "",
                triggerCount: 0
            }
        };
    }
}

interface IProps {
    data?: any,
    events: event[],
    onSave: any,
    onDelete?: any,
    new?: boolean,
    renderButton?:boolean
}

interface IValidate {
    event: boolean,
    week: boolean,
    time: boolean,
    calendar: boolean
}

const clearWarnings = () => {
    return {event: false, week: false, time: false, calendar: false};
}

interface freshSchedule {
    name?: string,
    uuid?: string,
    eventName: string,
    eventId: string,
    active: boolean,
    mode: number,
    taskA: boolean,
    taskB: boolean,
    taskC: boolean
    eventGroup: string,
    createdBy: string,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    triggerCount: number,
    lastUser: string,
    created: string,
    lastTriggeredBySchedule: string,
    lastTriggeredByUser: string,
    weekly?: boolean

}

const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($schedule: ScheduleInput!){
  createSchedule(schedule: $schedule){name,
    active,
    mode,
    event{name,id,taskA,taskB,taskC},
    uuid,
    calendar{weekly,monday,tuesday,wednesday,thursday,friday,saturday,sunday,startDate,endDate,startTime,endTime},
    analytics{created,createdBy,lastTriggeredBySchedule,lastTriggeredByUser,lastUser,triggerCount}
  
  }
  }

`;

const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($schedule: ScheduleInput!){
  updateSchedule(schedule: $schedule){name,
    active,
    mode,
    event{name,id,taskA,taskB,taskC},
    uuid,
    calendar{weekly,monday,tuesday,wednesday,thursday,friday,saturday,sunday,startDate,endDate,startTime,endTime},
    analytics{created,createdBy,lastTriggeredBySchedule,lastTriggeredByUser,lastUser,triggerCount}
  
  }
  }

`;

const DELETE_SCHEDULE = gql`
  mutation DeleteSchedule($uuid: String){
  deleteSchedule(uuid: $uuid)
  }

`;

const Editor = (props: IProps) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const [config, setConfig] = React.useState(initializeConfig(props.data, props.new, props.events[0]));
    const [validationWarning, setValidationWarning] = React.useState(clearWarnings());
    const [showDelete, setShowDelete] = React.useState(false);
    const [prevTime, setPrevTime] = React.useState(''); //fixes bug where time obj was force saving on cancel
    const [errorVisible, setErrorVisible] = React.useState(false);
    const [createSchedule, {error: createError,loading:createLoading}] = useMutation<{ createSchedule: schedule }, { schedule: freshSchedule }>(CREATE_SCHEDULE, {
        variables: {
            schedule: {
                name: config.name,
                eventName: config.event.name,
                eventId: config.event.id,
                active: config.active,
                mode: config.mode,
                taskA: config.event.taskA,
                taskB: config.event.taskB,
                taskC: config.event.taskC,
                eventGroup: config.event.group,
                createdBy: config.analytics.createdBy,
                monday: config.calendar.monday,
                tuesday: config.calendar.tuesday,
                wednesday: config.calendar.wednesday,
                thursday: config.calendar.thursday,
                friday: config.calendar.friday,
                saturday: config.calendar.saturday,
                sunday: config.calendar.sunday,
                startDate: config.calendar.startDate,
                endDate: config.calendar.endDate,
                startTime: config.calendar.startTime,
                endTime: config.calendar.endTime,
                uuid: '',
                triggerCount: 0,
                lastUser: '',
                created: Date.now().toString(),
                lastTriggeredBySchedule: '',
                lastTriggeredByUser: '',
                weekly: false

            }
        }
    });
    const [updateSchedule, {error: updateError,loading:updateLoading}] = useMutation<{ updateSchedule: schedule }, { schedule: freshSchedule }>(UPDATE_SCHEDULE, {
        variables: {
            schedule: {
                name: config.name,
                eventName: config.event.name,
                eventId: config.event.id,
                active: config.active,
                mode: config.mode,
                taskA: config.event.taskA,
                taskB: config.event.taskB,
                taskC: config.event.taskC,
                eventGroup: config.event.group,
                createdBy: config.analytics.createdBy,
                monday: config.calendar.monday,
                tuesday: config.calendar.tuesday,
                wednesday: config.calendar.wednesday,
                thursday: config.calendar.thursday,
                friday: config.calendar.friday,
                saturday: config.calendar.saturday,
                sunday: config.calendar.sunday,
                startDate: config.calendar.startDate,
                endDate: config.calendar.endDate,
                startTime: config.calendar.startTime,
                endTime: config.calendar.endTime,
                uuid: config.uuid,
                triggerCount: config.analytics.triggerCount,
                lastUser: config.analytics.lastUser,
                created: config.analytics.created,
                lastTriggeredBySchedule: config.analytics.lastTriggeredBySchedule,
                lastTriggeredByUser: config.analytics.lastTriggeredByUser,
                weekly: config.calendar.weekly

            }
        }
    });
    const [deleteSchedule, {error: deleteError,loading:deleteLoading}] = useMutation<{ deleteSchedule: boolean }, { uuid: string }>(DELETE_SCHEDULE, {
        variables: {
            uuid: config.uuid
        }
    });

    const showModal = () => {

        setConfig(initializeConfig(props.data, props.new, props.events[0]));
        setPrevTime(config.calendar.startTime);
        setIsOpen(true);
    };

    // const dropdownStyle = {
    //     height: "auto",
    //     maxHeight: "200px",
    // overflowX: "hidden"
    // };



    const clearParameters = () => {
        setValidationWarning(clearWarnings());
        setShowDelete(false);
    }

    const hideModal = () => {
        setIsOpen(false);
        let data: schedule = {...config};
        data.calendar.startTime = prevTime;
        setConfig(data);
        clearParameters();
        setErrorVisible(false);
    };

    const hideModalAndSave = () => {
        if(createLoading || updateLoading) return;
        //check if all mandatory fields have been filled.
        let val: IValidate = {...validationWarning};

        if (config.event.name === 'Select') {
            val.event = true;
            setValidationWarning(val);
        } else if (!config.calendar.monday && !config.calendar.tuesday && !config.calendar.wednesday && !config.calendar.thursday && !config.calendar.friday && !config.calendar.saturday && !config.calendar.sunday) {
            val.week = true;
            setValidationWarning(val);
        } else {
            if (props.new) { // create new schedule
                let data: schedule = {...config};
                data.analytics.created = Date.now().toString();
                data.analytics.createdBy = 'system';
                setConfig(data);

                createSchedule().then(r => {
                    let newSchedule = r.data ? r.data.createSchedule : data;

                    setIsOpen(false);
                    props.onSave({...newSchedule});
                    clearParameters();
                })
                    .catch(() => {
                        setErrorVisible(true);
                        return;
                    });
            } else { // update existent schedule
                updateSchedule().then(r => {

                    let updatedSchedule = r.data ? r.data.updateSchedule : config;

                    setIsOpen(false);
                    props.onSave({...updatedSchedule});
                    clearParameters();
                })
                    .catch((e) => {
                        console.log(e)
                        setErrorVisible(true);
                        return;
                    });
            }

            setErrorVisible(false);

        }
    };

    const updateEvent = (uuid: string) => {

        let data: schedule = {...config};
        for (let el of props.events) {
            if (el.id === uuid) {
                data.event = el;
            }
        }

        setConfig(data);

    };

    const setIcon = () => {
        if (props.new) {
            return (<FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 'large', width: '100px'}}/>);
        }
        return (<FontAwesomeIcon style={{color: 'black'}} icon={faEdit}/>);
    };

    const deleteWarning = (t: number) => {


        if (showDelete) {
            //throw delete event
            deleteSchedule().then((r)=>{
                if(r.data && r.data.deleteSchedule) {
                    props.onDelete();
                    hideModal();
                    return;
                }
            }).catch(()=>{ setErrorVisible(true);});

        } else {
            setShowDelete(true);
            setTimeout(() => {
                setShowDelete(false);
            }, t);
        }

        setErrorVisible(false);
    };

    const handleSwitchChange = () => {
        let data: schedule = {...config};
        data.active = !data.active;
        setConfig(data);
    };

    const handleTimeChange = (t: any) => {
        let data: schedule = {...config};
        data.calendar.startTime = t;
        setConfig(data);
    };

    const handleModeChange = (mode: number) => {
        let data: schedule = {...config};
        data.mode = mode;
        setConfig(data);
    };

    const handleTaskChange = (checked: boolean, task: number) => {
        let data: schedule = {...config};

        switch (task) {
            case (1): {
                data.event.taskA = checked;
                break;
            }
            case (2): {
                data.event.taskB = checked;
                break;
            }
            case (3): {
                data.event.taskC = checked;
                break;
            }
        }

        setConfig(data);
    };

    const handleWeekChange = (weekId: number) => {
        let data: schedule = {...config};
        switch (weekId) {
            case (1): {
                //monday
                data.calendar.monday = !data.calendar.monday;
                if (data.calendar.monday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (2): {
                //monday
                data.calendar.tuesday = !data.calendar.tuesday;
                if (data.calendar.tuesday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (3): {
                //monday
                data.calendar.wednesday = !data.calendar.wednesday;
                if (data.calendar.wednesday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (4): {
                //monday
                data.calendar.thursday = !data.calendar.thursday;
                if (data.calendar.thursday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (5): {
                //monday
                data.calendar.friday = !data.calendar.friday;
                if (data.calendar.friday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (6): {
                //monday
                data.calendar.saturday = !data.calendar.saturday;
                if (data.calendar.saturday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }
            case (7): {
                //monday
                data.calendar.sunday = !data.calendar.sunday;
                if (data.calendar.sunday) {
                    let val: IValidate = {...validationWarning};
                    val.week = false;
                    setValidationWarning(val)
                }
                break;
            }

        }
        setConfig(data);
    };

    const handleDateChange = (range: any) => {
        let data: schedule = {...config};
        data.calendar.startDate = range.selection.startDate;
        data.calendar.endDate = range.selection.endDate;
        setConfig(data);
    };


    return (
        <>
            {props.renderButton ?  <Button variant='light' style={{opacity:0.9}} onClick={()=>showModal()} >
                <span onClick={showModal}>{setIcon()}</span>
            </Button> : <span onClick={showModal}>{setIcon()}</span> }

            <Modal dialogClassName="modal-90w" show={isOpen} onHide={hideModal} keyboard={false}>
                <Modal.Header>
                    <Modal.Title className={'user-select-none'} style={{fontSize: 'small'}}><FontAwesomeIcon
                        icon={faEdit}/> {props.new ? 'New Schedule' : 'Edit Schedule'}  </Modal.Title>
                    <div className={'small text-secondary'}><Form>
                        <Form.Check
                            type="switch"
                            id="active-switch"
                            onChange={handleSwitchChange}
                            checked={config.active}
                            label={config.active ? "" : "inactive"}
                        />
                    </Form>
                    </div>
                </Modal.Header>
                <Modal.Body className={'user-select-none mx-auto w-100'}>
                    <p className={'text-primary text-center '}>Run this schedule</p>
                    <ButtonGroup size='sm' className="d-flex justify-content-center w-100">
                        <Button variant={(config.mode === 1) ? 'primary' : 'muted'} onClick={() => {
                            handleModeChange(1);
                        }}><strong>Daily</strong></Button>
                        <Button variant={(config.mode === 2) ? 'primary' : 'muted'} onClick={() => {
                            handleModeChange(2);
                        }}><strong>Weekly</strong></Button>
                        <Button variant={(config.mode === 3) ? 'primary' : 'muted'} onClick={() => {
                            handleModeChange(3);
                        }}><strong>On..</strong></Button>
                    </ButtonGroup>

                    <Form>
                        <Form.Group className={'d-flex justify-content-center w-100'}>

                            <div>
                                <br/>

                                <DateTimeDisplay calendar={config.calendar} mode={config.mode}
                                                 onTimeChange={(t: string) => {
                                                     handleTimeChange(t);
                                                 }} onWeekChange={(weekId: number) => handleWeekChange(weekId)}
                                                 onDateChange={(range: any) => {
                                                     handleDateChange(range)
                                                 }}/>
                                <span className='text-danger d-flex justify-content-center mt-2'
                                > {validationWarning.week ? 'Select at least one week day.' : null}</span>
                            </div>
                        </Form.Group>

                        <br/>
                        <Form.Group style={{width: '80%'}}>
                            <Form.Label className={'text-secondary text-center'}>Select Event</Form.Label>
                            <Form.Control as="select" onChange={(selected) => {
                                updateEvent(selected.target.value)
                            }} value={config.event.id}>

                                {props.events.map((event: event, index: number) => <option key={event.id}
                                                                                           value={event.id}>{event.name}</option>)}
                            </Form.Control>
                            <span className='text-danger'
                                  style={{width: '300px'}}> {validationWarning.event ? 'Event not selected' : null}</span>

                        </Form.Group>
                        <br/>

                        <Form.Group className={'text-secondary'}>
                            {console.log(config.event)}

                            <div className={'d-flex'}>
                                <Form.Check
                                    type={"checkbox"}
                                    id={`check-show`}
                                    label={`Show Mode`}
                                    className={'mr-2'}
                                    onChange={({target}) => {
                                        handleTaskChange(target.checked, 2)
                                    }}
                                    checked={config.event.taskB}
                                />
                                <FontAwesomeIcon icon={faGem} className={'mt-1'}/>
                            </div>
                            <br/>
                            <div className={'d-flex'}>
                                <Form.Check
                                    type={"checkbox"}
                                    id={`check-radar`}
                                    label={`Radar Mode`}
                                    className={'mr-2'}
                                    onChange={({target}) => {
                                        handleTaskChange(target.checked, 1)
                                    }}
                                    checked={config.event.taskA}
                                /><FontAwesomeIcon icon={faSatelliteDish} className={'mt-1'}/>
                            </div>
                            <br/>
                            <div className={'d-flex'}>
                                <Form.Check
                                    type={"checkbox"}
                                    id={`check-instagram`}
                                    label={`Hashtag Mode`}
                                    className={'mr-2'}
                                    onChange={({target}) => {
                                        handleTaskChange(target.checked, 3)
                                    }}
                                    checked={config.event.taskC}
                                />
                                <FontAwesomeIcon icon={faInstagram} className={'mt-1'}/>
                            </div>

                        </Form.Group>

                    </Form>

                    {(errorVisible && (createError || updateError || deleteError)) ? <div className="alert alert-danger">

                        Server unavailable
                    </div> : null}

                </Modal.Body>
                <Modal.Footer className={'d-flex'}>
                    {props.new ? null : <button
                        className={`mr-auto p-2 btn btn-link fade-in-1s text-decoration-none ${showDelete ? 'text-danger btn-outline-light' : 'text-dark'}`}
                        onClick={() => {
                            deleteWarning(5000)
                        }}><FontAwesomeIcon icon={faTrashAlt}/>{showDelete ?
                        <span className='fade-in-500ms ml-2'>Delete schedule </span> : null}</button>}
                    <Button variant="muted" className={'p-2'} onClick={hideModal}>Cancel</Button>
                    <Button variant={ updateLoading || createLoading ? 'secondary' : 'muted'} className={'p-2'} onClick={hideModalAndSave}>Done</Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default Editor;

//  <FontAwesomeIcon icon={faEdit} />
/*
<span className='d-flex justify-content-center'> <DropdownButton id="dropdown-basic-button" size='sm' title={config.event.name} className={`dropdownStyle mb-5`} >
                        {props.events.map((event: event) => <Dropdown.Item key={event.id} onClick={()=>{updateEvent(event)}}>{event.name}</Dropdown.Item>)}
                       </DropdownButton> {eventWarning ? <span className='text-danger' style={{width:'300px'}}>Event not selected</span> : null}</span>
 */
