// @flow
import React from 'react';
import {gql, useQuery} from '@apollo/client';
import SortableSchedules from "../components/sortable-schedules";
import {event, eventsData, schedule, statusData} from "../interfaces/interfaces";
import {useMediaQuery} from 'react-responsive';
import Loader from "react-loader-spinner";


const GET_SCHEDULES = gql`
  query {schedules {
     name,
    active,
    mode,
    event{name,id,taskA,taskB,taskC,group},
    uuid,
    calendar{weekly,monday,tuesday,wednesday,thursday,friday,saturday,sunday,startDate,endDate,startTime,endTime},
    analytics{created,createdBy,lastTriggeredBySchedule,lastTriggeredByUser,lastUser,triggerCount}
  }}
`;

const GET_EVENTS = gql`
  query {events {
     name,
    id,
    taskA,
    taskB,
    taskC,
    group 
  }}
`;

const GET_STATUS = gql`
  query {
  liveSchedules
  }
`;

interface schedulesData {
    schedules: schedule[],
    events: event[]
}


type Props = {};


const SchedulerRoute = (props: Props) => {

    const {
        loading: schedulesLoading,
        error: schedulesError,
        data: schedulesData
    } = useQuery<schedulesData>(GET_SCHEDULES);

    const {
        loading: eventsLoading,
        error: eventsError,
        data: eventsData
    } = useQuery<eventsData>(GET_EVENTS); // non-optimal, we could request events with the schedules query,
    // but there is an apollo bug where the events query was overwriting the schedules event data, separating queries fixes it.


    const {
        loading: statusLoading,
        error: statusError,
        data: statusData
    } = useQuery<statusData>(GET_STATUS, {pollInterval: 60000});

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});


    return (
        <div className={'user-select-none'}>

            {/*{statusLoading ? <p className={'text-secondary'}>...</p> : statusError ? 'error' : <LiveStatusBar statusData={statusData}/>}*/}


            <div className={'card mt-3'} style={{
                backgroundColor: "lightgrey",
                width: '90%',
                height: isTabletOrMobile ? '77vh' : '82vh',
                margin: 'auto',
                alignItems: 'center',
                overflow: "auto"
            }}>

                {(eventsLoading || schedulesLoading || statusLoading) ?
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={50}
                        width={50}
                    /> : ((schedulesError || statusError || eventsError) ?
                        <p className={'m-1'}>Service unavailable.</p> :
                        <SortableSchedules live={statusData?.liveSchedules}
                                           events={eventsData!.events.length > 0 ? JSON.parse(JSON.stringify(eventsData!.events)) : []}
                                           schedules={schedulesData!.schedules.length > 0 ? JSON.parse(JSON.stringify(schedulesData!.schedules)) : []} onConflict={()=>{window.location.reload();}}/>)}


            </div>
        </div>
    );
};
export default SchedulerRoute;
