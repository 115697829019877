// @flow
import * as React from 'react';
import '../styles/login.css';
import {faGlobeAsia} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {event} from "../interfaces/interfaces";
import {Button, ButtonGroup, Image} from "react-bootstrap";
import brisbaneLogo from '../images/brisbane.svg'
import {useParams} from "react-router-dom";
import Loader from 'react-loader-spinner';


type Props = {
    onLogin:any,
    unavailable:boolean
};

const USER_LOGIN = gql`
  mutation userLogin($username: String,$password: String){
  userLogin(username: $username, password: $password)
  }`;

const TOKEN_VALIDATE = gql`
  mutation tokenValidate($token: String){
  tokenValidate(token: $token)
  }`;


export const Login = (props: Props) => {

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [loginState,setLoginState] = useState(0);
    const [renderLoginForm,setRenderLoginFrom] = useState(false);
    const [userLogin, {error: userLoginError,loading:userLoginLoading}] = useMutation<{ userLogin: string }, { username: string, password: string }>(USER_LOGIN, {
        variables: {
            username:username,
            password:password
        }
    });
    const [tokenCheck, {error: tokenCheckError,loading:tokenChecking}] = useMutation<{ tokenValidate: boolean }, { token: string }>(TOKEN_VALIDATE, {
        variables: {
            token: localStorage.getItem('token') || ''
        }
    });
   // const p = useParams();
   //console.log(p)
    const handleSubmit = (e:any) =>{
        e.preventDefault();
        userLogin().then(res =>{
            if(res && res.data){
                if(res.data.userLogin === 'invalid user'){
                    setLoginState(1);
                }
                else if(res.data.userLogin === 'invalid password'){
                    setLoginState(2);
                }
                else{ //success
                    setLoginState(3);
                     props.onLogin();
                    localStorage.setItem("token",res.data.userLogin);

                }
            }
        })
            .catch((e)=>{
                console.log(`login error: ${e}`);
            });
    }

    useEffect(()=>{
        setLoginState(0);
    },[userLoginError]);

    useEffect( ()=>{

        //read local storage
        const token = localStorage.getItem('token');
        if(token && token.length > 10){
           // console.log(token)
            tokenCheck().then((res)=>{
               const valid = res.data ? res.data.tokenValidate : false;
             //  console.log(res.data)
               if(valid) {
                  // console.log('token valid')
                   setTimeout(()=>setLoginState(3),500);
                   setTimeout(()=>{
                       props.onLogin();
                   },1000);
               }
               else{
                   setTimeout(()=>{
                      setRenderLoginFrom(true);
                   },1500);
                  // console.log('token invalid')
               }
            })
                .catch((e)=>{
                    console.log(e);
                })
        }
    },[]) //did mount

    const loginForm = () =>{
       return ( <form onSubmit={handleSubmit}>
           <div className="form-group">
               <label>Login to Gasworks Scheduler</label>
               <input type="text" className="form-control" placeholder="User Name" value={username} onChange={(e)=>{setUsername(e.target.value); setLoginState(0)}}/>
           </div>
           {loginState === 1 ? <div className="alert alert-danger" role="alert">
               invalid user
           </div> : null}
           <div className="form-group">

               <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value); setLoginState(0)}}/>
           </div>
           {loginState === 2 ? <div className="alert alert-danger" role="alert">
               Invalid password
           </div> : null}
           <button type="submit" className="btn btn-dark">Login</button>
           {/*<button type="submit" className="btn btn-secondary">Register</button>*/}


       </form>);
    }

    return (
        <div>
            <div className="sidenav bg-dark" >
                <div className="login-main-text" >
                    <Image src={brisbaneLogo}/>
                    <h2 className={'m-2'}>City Lights App</h2>
                </div>
            </div>
            <div className="main">
                <div className="col-md-6 col-sm-12">

                    <div className="login-form">
                        {userLoginError || props.unavailable ? <div className="alert alert-danger" role="alert">
                            service unavailable
                        </div> : null}
                        {loginState === 3 ? <div className="alert alert-success" role="alert">
                            logging in..
                        </div> :  null}
                        {(!renderLoginForm && loginState !== 3) ? <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        /> : <div>Please login using the live control page.</div>}
                        {/*{(renderLoginForm && !(userLoginError || props.unavailable)) ? loginForm() : null}*/}
                        {/*{(renderLoginForm) ? <div className={'m-5'}>*/}
                        {/*    /!*<Button href={'https://gasworks.rabonesystems.net/'} className={''}>Return to Live Control</Button>*!/*/}
                        {/*    <div>Please login using the live control page.</div>*/}
                        {/*</div> : null}*/}

                    </div>
                </div>
            </div>
        </div>
    );
};
