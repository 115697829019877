// @flow
import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGem, faSatelliteDish} from "@fortawesome/free-solid-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {CurrentTime} from "./currentTime";
import {statusData} from "../interfaces/interfaces";
import {gql, useQuery} from "@apollo/client";

type Props = {

};
const GET_STATUS = gql`
  query {status {
     name,
     id,
    taskA,
    taskB,
    taskC 
  },
  liveSchedules
  }
`;

export const
    LiveStatusBar = () => {
    const {
        loading: statusLoading,
        error: statusError,
        data: statusData
    } = useQuery<statusData>(GET_STATUS,{pollInterval: 10000});
    console.log(statusData)

    if(statusData && statusData.status && statusData.status.length <= 0) return null;
    if(statusLoading) return null;
    if(statusError) return null;
    return (<div className={'font-size-secondary w-100 bg-light pt-1 pb-1 text-secondary user-select-none d-flex justify-content-between align-items-center'}>
        <div className={'d-flex justify-content-left align-items-center'}>

            <div style={{width: '110px'}}>
                <div className={'d-flex justify-content-between ml-3 mr-3'} >
                    <OverlayTrigger

                        placement={'bottom'}

                        overlay={
                            <Tooltip id={'tooltip-show'} >
                                <div className={'font-size-secondary'}> Show mode <strong>{statusData!.status[0].taskB ? 'on' : ' off'}</strong></div>
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faGem}
                                         className={statusData!.status[0].taskB ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>
                    <OverlayTrigger

                        placement={'bottom'}
                        overlay={
                            <Tooltip id={'tooltip-radar'}>
                                <div className={'font-size-secondary'}> Radar mode <strong>{statusData!.status[0].taskA ? 'on' : ' off'}</strong></div>
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faSatelliteDish}
                                         className={statusData!.status[0].taskA ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>
                    <OverlayTrigger

                        placement={'bottom'}
                        overlay={
                            <Tooltip id={'tooltip-instagram'}>
                                <div className={'font-size-secondary'}> Instagram hashtags <strong>{statusData!.status[0].taskC ? 'on' : ' off'}</strong></div>
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faInstagram}
                                         className={statusData!.status[0].taskC ? 'text-primary' : 'text-secondary'}/>
                    </OverlayTrigger>

                </div>


            </div>
            <OverlayTrigger
                placement={'bottom'}
                overlay={
                    <Tooltip id={'tooltip-show'}>
                        <div className={'font-size-secondary'}> Current status: <strong>{statusData!.status[0].name}{statusData!.status.length > 2 ? ' + others..' : null}</strong></div>
                    </Tooltip>
                }>
                <a>
                <strong>{statusData!.status[0].name}</strong>{statusData!.status.length > 2 ? ' + others..' : null}
                </a>
            </OverlayTrigger>
        </div>
        <div className={'mr-3'} ><strong><CurrentTime/></strong></div>
    </div>);
};
