//Draggable elements
import {SortableElement} from "react-sortable-hoc";
import React, {useState} from "react";
import DragHandler from "./dragHandler";
import '../styles/drag.css';
import '../styles/animations.css';
import Schedule from "./base-schedule";
import {analytics, event, schedule} from "../interfaces/interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faBars} from "@fortawesome/free-solid-svg-icons";


interface propsI {
    schedule:schedule,
    index:number,
    events:event[],
    onUpdate:any,
    onDelete:any
}

//SortableElement
const SortItem = (({ schedule, index, events,onUpdate, onDelete }:propsI) => {
    const [updatedAnimation,setUpdatedAnimation] = useState('');

    const renderHandler = (schedule:schedule) => {
        return (
            <DragHandler active={schedule.active}/>
        )
    }

    const newAnimation = (created:string) =>{

        if (created && Date.now() - parseInt(created) <=5000){
            return ' newSchedule';
        }

        return '';

    }





    const w='150px';
  return (

          <div className={'card align-middle text-secondary user-select-none mb-1 shadow-sm align-items-center '+ newAnimation(schedule.analytics.created) + updatedAnimation} style={{ width:'100%', display:'inline-flex',
              flexWrap:'wrap',gap:'12px', opacity: (schedule.active ? '1':'.6'),
              justifyContent:'space-between',flexDirection:'row',paddingLeft:'5px',paddingRight:'5px'}}>
              {schedule.active? renderHandler(schedule)
                 : <FontAwesomeIcon icon={faBan}/> } <small style={{width:w}} ><strong> {schedule.event.name}</strong></small>
              <Schedule onUpdate={(data:schedule)=>{onUpdate(data,index);setUpdatedAnimation('updatedSchedule'); setTimeout(()=>setUpdatedAnimation(''),2000);}} onDelete={onDelete}
              key={schedule.uuid} data={schedule} events={events}/>
              </div>
              );
});

export default SortItem;


//  <div className={"dragElement"} style={{width: '100%'}}>
//         {value.name}
//         <DragHandler/>
//     </div>
