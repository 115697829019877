// @flow
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {event, eventsData, statusData} from "../interfaces/interfaces";
import {Button, ButtonGroup} from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import {ChromePicker} from 'react-color';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFillDrip} from "@fortawesome/free-solid-svg-icons";

type Props = {}
const GET_EVENTS = gql`
  query {events {
     name,
    id,
    taskA,
    taskB,
    taskC,
    group,
    state
  }}
`;

const GET_STATUS = gql`
  query {status {
     name,
     id,
    taskA,
    taskB,
    taskC 
  },
  liveSchedules,
  colorState{
  color,
  enabled
  }
  }
`;

const SET_EVENT = gql`
  mutation SetEvent($id: String){
  setEvent(id: $id)
  {
  id
  }
  }`;

const SET_COLOR = gql`
  mutation SetColor($hex: String,$enabled: Boolean){
  setColorFilter(hex: $hex,enabled:$enabled)
  }`;


export const LiveRoute = (props: Props) => {

    const [statusQuery, {
        loading: statusLoading,
        error: statusError,
        data: statusData,
    }] = useLazyQuery<statusData>(GET_STATUS, {pollInterval: 30000});

    const [getEvents, {
        loading: eventsLoading,
        error: eventsError,
        data: eventsData,
        refetch: eventsFetch
    }] = useLazyQuery<eventsData>(GET_EVENTS);


    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});


    const [trigger, setTrigger] = useState(false);
    const [triggerSafe, setTriggerSafe] = useState(true);

    const [eventTarget, setEventTarget] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [pickerColor, setPickerColor] = useState('#0007ff');
    const [pickerEnabled, setPickerEnabled] = useState(false);
    const [pickerMounted, setPickerMounted] = useState(false);
    const [setColor, {
        error: setColorError,
        loading: setColorLoading
    }] = useMutation<{ setColor: boolean }, { hex: String, enabled: Boolean }>(SET_COLOR, {
        variables: {
            hex: pickerColor,
            enabled: pickerEnabled
        }
    });


    const scrollRef = useRef<HTMLButtonElement>(null);


    const [setEvent, {
        error: setEventError,
        loading: setEventLoading
    }] = useMutation<{ setEvent: event }, { id: string }>(SET_EVENT, {
        variables: {
            id: eventTarget
        }
    });


    function triggerEvent(id: string) {
        setEventTarget(id);
        setTrigger(true);


    }


    useEffect(() => {

        if (trigger && triggerSafe) {
            setEvent().then(() => {
                //event call successful
                //update array instantly

                setTimeout(() => {
                    if (eventsFetch) eventsFetch()
                }, 100);
            });

            setTimeout(() => {
                setTriggerSafe(true);
            }, 1000); //rate limit press to 1s
            setTrigger(false);
            setTriggerSafe(false);

        }

    }, [trigger, triggerSafe]);

    useEffect(() => {
        if (showPicker) {
            setColor();
        }
    }, [pickerEnabled])

    function checkScroll(id: string, state?: string) {
        if (id === '15') return scrollRef!;
        if (state && (state === 'running' || state === 'holding_at_end' || state === 'paused')) {
            return scrollRef!;
        }

        return null;
    }


    useEffect(() => {
        statusQuery();
        getEvents();


    }, []); //did mount

    useLayoutEffect(() => {
        if (scrollRef) {
            // scrollRef!.current!.scrollIntoView();
        }
    });

    //{event.state && (event.state === 'running' || event.state === 'holding_at_end' || event.state === 'paused')) ? scrollRef : null}
    function eventBar(event: event, id: string) {
        return (

            <Button key={id} onClick={() => {
                if (event.state && (event.state === 'running' || event.state === 'holding_at_end' || event.state === 'paused')) {
                    triggerEvent('-1')
                } else {
                    triggerEvent(id);
                }
            }} ref={checkScroll(event.id, event.state)}
                    className={' align-middle text-secondary user-select-none mb-1 shadow-sm align-items-center ' + (event.state && (event.state === 'running' || event.state === 'holding_at_end' || event.state === 'paused') ? 'text-dark' : 'text-secondary')}
                    style={{
                        width: '100%', display: 'inline-flex',
                        flexWrap: 'wrap', gap: '12px',
                        justifyContent: 'space-between', flexDirection: 'row', paddingLeft: '5px', paddingRight: '5px'
                    }}
                    variant={(event.state && (event.state === 'running' || event.state === 'holding_at_end' || event.state === 'paused')) ? 'warning' : 'light'}>{event.name}</Button>

        );
    }

    function colorPicker() {
        if (!pickerMounted) {
            setPickerColor(statusData!.colorState.color);
            setPickerEnabled(statusData!.colorState.enabled);
            setPickerMounted(true);
        }

        if (!showPicker) return null;

        return (
            <div className={'d-flex'} style={{
                width: '100%',
                backgroundColor: (statusData!.colorState.enabled ? pickerColor : '')
            }}>
                <ChromePicker onChange={(color) => setPickerColor(color.hex)} color={pickerColor}
                              onChangeComplete={(color) => {
                                  setPickerColor(color.hex);
                                  setColor().then(r => console.log(r));
                              }}
                              disableAlpha={true}/>
                <div className={'m-auto card p-1'}>
                    <p className={'text-secondary m-3 user-select-none'}>Colour Filter</p>
                    <ButtonGroup>
                        <Button
                            className={(statusData!.colorState.enabled || pickerEnabled) ? 'btn-warning' : 'btn-primary'}
                            onClick={() => {
                                setPickerEnabled(true);
                            }}>On</Button>
                        <Button
                            className={(statusData!.colorState.enabled || pickerEnabled) ? 'btn-primary' : 'btn-warning'}
                            onClick={() => {
                                setPickerEnabled(false);
                            }}>Off</Button>
                    </ButtonGroup>
                </div>
            </div>

        );
    }

    const pickerSize = '35px';

    return (
        <div>

            <div className={'card mt-3'} style={{
                backgroundColor: "lightgrey",
                width: '90%',
                height: isTabletOrMobile ? '77vh' : '82vh',
                margin: 'auto',
                alignItems: 'center',
                overflow: "auto"
            }}>
                <div style={{width: '100%'}}>
                    <div className={'sticky-top'}>
                        <div className={'card'}>
                            <button className={'card m-1'}
                                    style={{width: pickerSize, height: pickerSize, backgroundColor: 'white'}}
                                    onClick={() => {
                                        setShowPicker(!showPicker)
                                    }}>{statusLoading ? <p className={'text-secondary'}>...</p> : statusError ? null :
                                (statusData ? <FontAwesomeIcon icon={faFillDrip} className={'m-auto'}
                                                               style={{color: (statusData!.colorState.enabled ? statusData!.colorState.color : 'dark-grey')}}/> : null)}</button>

                        </div>
                    </div>
                    {statusLoading ?
                        <p className={'text-secondary'}>...</p> : statusError ? null : (statusData ? colorPicker() : null)}

                    {/*sort((a,b)=> a.name.localeCompare(b.name))*/}
                    {eventsData ? eventsData!.events.map((event: event) =>
                        (eventBar(event, event.id))
                    ) : null}

                </div>
            </div>

        </div>
    );
};
