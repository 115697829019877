// @flow
import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";

type Props = {
    onHide:any;
    hideInactive:boolean;
    live?:number
};


export const FilterBar = ({hideInactive,onHide,live}: Props) => {
    const handleSwitchChange = () =>{
        onHide(!hideInactive);
    };



    return (
        <div className={'card font-size-secondary' }>
            <div className={'d-flex justify-content-between m-1'}>
                {/*<div className={'ml-2 text-secondary user-select-none'} style={{opacity:'.4'}}>Filter</div>*/}
            <div className={'d-flex justify-content-between'}>

                <Form>
                <Form.Check
                    type="switch"
                    id="hide-switch"
                    onChange={handleSwitchChange}
                    checked={!hideInactive}
                />
            </Form>
                <div className={'mr-2 text-secondary user-select-none '} style={{opacity:'.4'}}><strong>Show Archived</strong></div>
            </div>
                <div style={{opacity:'.4'}}><strong>Live: {live ? live : 0}</strong></div>
                </div>



        </div>
    );
};
